.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid black;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
  background: #16728D;
  color: white;
  white-space: nowrap;
  min-width: 100px;
  border-bottom: 1px solid #16728D;
  border-top: 1px solid #16728D;
  border-right: 1px solid #3d3b3b;
}
.ant-table-tbody > tr.ant-table-row-level-0:nth-child(even) > td {
  background: #eeeeee;
}
.ant-table-tbody > tr.ant-table-row-level-0 > td {
  border-top: 1px solid black;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
  border-right: 1px solid #8f8d8d;
}
.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #d5d5d5;
}
.ant-table-tbody > tr.ant-table-row-level-1 {
  background: #d5d5d5;
}
.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: #bbbbbb;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 4px 8px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
  white-space: nowrap;
  min-width: 100px;
}
.editable-cell-value-wrap {
  background: #f5f5f5;
  padding-right: 24;
  border-radius: 3px;
  padding-left: 5px;
}
.ant-table-tbody > tr > td {
  height: 5px;
}
